/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ForecastConfiguration,
  ForecastConfigurationFromJSON,
  ForecastConfigurationFromJSONTyped,
  ForecastConfigurationToJSON,
  ForecastResult,
  ForecastResultFromJSON,
  ForecastResultFromJSONTyped,
  ForecastResultToJSON,
} from "./";

/**
 *
 * @export
 * @interface ForecastForInitialRequestsResultItem
 */
export interface ForecastForInitialRequestsResultItem {
  /**
   *
   * @type {ForecastConfiguration}
   * @memberof ForecastForInitialRequestsResultItem
   */
  _configuration?: ForecastConfiguration;
  /**
   *
   * @type {ForecastResult}
   * @memberof ForecastForInitialRequestsResultItem
   */
  result: ForecastResult;
}

export function ForecastForInitialRequestsResultItemFromJSON(
  json: any
): ForecastForInitialRequestsResultItem {
  return ForecastForInitialRequestsResultItemFromJSONTyped(json, false);
}

export function ForecastForInitialRequestsResultItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ForecastForInitialRequestsResultItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    _configuration: !exists(json, "configuration")
      ? undefined
      : ForecastConfigurationFromJSON(json["configuration"]),
    result: ForecastResultFromJSON(json["result"]),
  };
}

export function ForecastForInitialRequestsResultItemToJSON(
  value?: ForecastForInitialRequestsResultItem | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    configuration: ForecastConfigurationToJSON(value._configuration),
    result: ForecastResultToJSON(value.result),
  };
}
