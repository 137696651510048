/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AuditlogModel,
  AuditlogModelFromJSON,
  AuditlogModelFromJSONTyped,
  AuditlogModelToJSON,
  CommercialInstructionRequest,
  CommercialInstructionRequestFromJSON,
  CommercialInstructionRequestFromJSONTyped,
  CommercialInstructionRequestToJSON,
  DateSpan,
  DateSpanFromJSON,
  DateSpanFromJSONTyped,
  DateSpanToJSON,
  ForecastConfiguration,
  ForecastConfigurationFromJSON,
  ForecastConfigurationFromJSONTyped,
  ForecastConfigurationToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
  OrderStatus,
  OrderStatusFromJSON,
  OrderStatusFromJSONTyped,
  OrderStatusToJSON,
  SubOrderRequest,
  SubOrderRequestFromJSON,
  SubOrderRequestFromJSONTyped,
  SubOrderRequestToJSON,
} from "./";

/**
 *
 * @export
 * @interface OrderRequest
 */
export interface OrderRequest {
  /**
   *
   * @type {number}
   * @memberof OrderRequest
   */
  id?: number | null;
  /**
   *
   * @type {OrderStatus}
   * @memberof OrderRequest
   */
  requestStatus?: OrderStatus;
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  contactEmail?: string | null;
  /**
   *
   * @type {number}
   * @memberof OrderRequest
   */
  productId: number;
  /**
   *
   * @type {number}
   * @memberof OrderRequest
   */
  advertiserId: number;
  /**
   *
   * @type {MediumEnum}
   * @memberof OrderRequest
   */
  medium: MediumEnum;
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  campaignName?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  comments?: string | null;
  /**
   *
   * @type {Array<SubOrderRequest>}
   * @memberof OrderRequest
   */
  subOrders?: Array<SubOrderRequest> | null;
  /**
   *
   * @type {boolean}
   * @memberof OrderRequest
   */
  spreadedSecondPart?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrderRequest
   */
  spreadedFirstPart?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrderRequest
   */
  readonly isSpreaded?: boolean;
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  invoice?: string | null;
  /**
   *
   * @type {Date}
   * @memberof OrderRequest
   */
  requestDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof OrderRequest
   */
  mutationTimeStamp?: Date;
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  errorMessage?: string | null;
  /**
   *
   * @type {DateSpan}
   * @memberof OrderRequest
   */
  period: DateSpan;
  /**
   *
   * @type {Array<AuditlogModel>}
   * @memberof OrderRequest
   */
  auditLogs?: Array<AuditlogModel> | null;
  /**
   *
   * @type {ForecastConfiguration}
   * @memberof OrderRequest
   */
  forecastConfiguration?: ForecastConfiguration;
  /**
   *
   * @type {Array<CommercialInstructionRequest>}
   * @memberof OrderRequest
   */
  instructions?: Array<CommercialInstructionRequest> | null;
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  crmAccount?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  crmOpportunity?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  crmContact?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  advertiser?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  contact?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderRequest
   */
  contactEmailExtra?: string | null;
  /**
   *
   * @type {number}
   * @memberof OrderRequest
   */
  originalBudget?: number;
}

export function OrderRequestFromJSON(json: any): OrderRequest {
  return OrderRequestFromJSONTyped(json, false);
}

export function OrderRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrderRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    requestStatus: !exists(json, "requestStatus")
      ? undefined
      : OrderStatusFromJSON(json["requestStatus"]),
    contactEmail: !exists(json, "contactEmail")
      ? undefined
      : json["contactEmail"],
    productId: json["productId"],
    advertiserId: json["advertiserId"],
    medium: MediumEnumFromJSON(json["medium"]),
    campaignName: !exists(json, "campaignName")
      ? undefined
      : json["campaignName"],
    comments: !exists(json, "comments") ? undefined : json["comments"],
    subOrders: !exists(json, "subOrders")
      ? undefined
      : json["subOrders"] === null
        ? null
        : (json["subOrders"] as Array<any>).map(SubOrderRequestFromJSON),
    spreadedSecondPart: !exists(json, "spreadedSecondPart")
      ? undefined
      : json["spreadedSecondPart"],
    spreadedFirstPart: !exists(json, "spreadedFirstPart")
      ? undefined
      : json["spreadedFirstPart"],
    isSpreaded: !exists(json, "isSpreaded") ? undefined : json["isSpreaded"],
    invoice: !exists(json, "invoice") ? undefined : json["invoice"],
    requestDate: !exists(json, "requestDate")
      ? undefined
      : new Date(json["requestDate"]),
    mutationTimeStamp: !exists(json, "mutationTimeStamp")
      ? undefined
      : new Date(json["mutationTimeStamp"]),
    errorMessage: !exists(json, "errorMessage")
      ? undefined
      : json["errorMessage"],
    period: DateSpanFromJSON(json["period"]),
    auditLogs: !exists(json, "auditLogs")
      ? undefined
      : json["auditLogs"] === null
        ? null
        : (json["auditLogs"] as Array<any>).map(AuditlogModelFromJSON),
    forecastConfiguration: !exists(json, "forecastConfiguration")
      ? undefined
      : ForecastConfigurationFromJSON(json["forecastConfiguration"]),
    instructions: !exists(json, "instructions")
      ? undefined
      : json["instructions"] === null
        ? null
        : (json["instructions"] as Array<any>).map(
            CommercialInstructionRequestFromJSON
          ),
    crmAccount: !exists(json, "crmAccount") ? undefined : json["crmAccount"],
    crmOpportunity: !exists(json, "crmOpportunity")
      ? undefined
      : json["crmOpportunity"],
    crmContact: !exists(json, "crmContact") ? undefined : json["crmContact"],
    advertiser: !exists(json, "advertiser") ? undefined : json["advertiser"],
    contact: !exists(json, "contact") ? undefined : json["contact"],
    contactEmailExtra: !exists(json, "contactEmailExtra")
      ? undefined
      : json["contactEmailExtra"],
    originalBudget: !exists(json, "originalBudget")
      ? undefined
      : json["originalBudget"],
  };
}

export function OrderRequestToJSON(value?: OrderRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    requestStatus: OrderStatusToJSON(value.requestStatus),
    contactEmail: value.contactEmail,
    productId: value.productId,
    advertiserId: value.advertiserId,
    medium: MediumEnumToJSON(value.medium),
    campaignName: value.campaignName,
    comments: value.comments,
    subOrders:
      value.subOrders === undefined
        ? undefined
        : value.subOrders === null
          ? null
          : (value.subOrders as Array<any>).map(SubOrderRequestToJSON),
    spreadedSecondPart: value.spreadedSecondPart,
    spreadedFirstPart: value.spreadedFirstPart,
    invoice: value.invoice,
    requestDate:
      value.requestDate === undefined
        ? undefined
        : value.requestDate.toISOString(),
    mutationTimeStamp:
      value.mutationTimeStamp === undefined
        ? undefined
        : value.mutationTimeStamp.toISOString(),
    errorMessage: value.errorMessage,
    period: DateSpanToJSON(value.period),
    auditLogs:
      value.auditLogs === undefined
        ? undefined
        : value.auditLogs === null
          ? null
          : (value.auditLogs as Array<any>).map(AuditlogModelToJSON),
    forecastConfiguration: ForecastConfigurationToJSON(
      value.forecastConfiguration
    ),
    instructions:
      value.instructions === undefined
        ? undefined
        : value.instructions === null
          ? null
          : (value.instructions as Array<any>).map(
              CommercialInstructionRequestToJSON
            ),
    crmAccount: value.crmAccount,
    crmOpportunity: value.crmOpportunity,
    crmContact: value.crmContact,
    advertiser: value.advertiser,
    contact: value.contact,
    contactEmailExtra: value.contactEmailExtra,
    originalBudget: value.originalBudget,
  };
}
