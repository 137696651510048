import { ForecastCoverageEnum } from "../../../api";

// eslint-disable-next-line import/prefer-default-export
export const forecastCoverageEnumTranslations: {
  [key in ForecastCoverageEnum]: string;
} = {
  [ForecastCoverageEnum.Coverage1]: "1+",
  [ForecastCoverageEnum.Coverage2]: "2+",
  [ForecastCoverageEnum.Coverage3]: "3+",
  [ForecastCoverageEnum.Coverage4]: "4+",
  [ForecastCoverageEnum.Coverage5]: "5+",
  [ForecastCoverageEnum.Coverage6]: "6+",
  [ForecastCoverageEnum.Coverage7]: "7+",
  [ForecastCoverageEnum.Coverage8]: "8+",
  [ForecastCoverageEnum.Coverage9]: "9+",
  [ForecastCoverageEnum.Coverage10]: "10+",
};
