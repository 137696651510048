/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ForecastCoverageEnum,
  ForecastCoverageEnumFromJSON,
  ForecastCoverageEnumFromJSONTyped,
  ForecastCoverageEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface ForecastConfiguration
 */
export interface ForecastConfiguration {
  /**
   *
   * @type {Array<string>}
   * @memberof ForecastConfiguration
   */
  conversionGroups?: Array<string> | null;
  /**
   *
   * @type {boolean}
   * @memberof ForecastConfiguration
   */
  displayContactDistribution?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ForecastConfiguration
   */
  displayAbsoluteNumbers?: boolean;
  /**
   *
   * @type {ForecastCoverageEnum}
   * @memberof ForecastConfiguration
   */
  additionalCoverage?: ForecastCoverageEnum;
}

export function ForecastConfigurationFromJSON(
  json: any
): ForecastConfiguration {
  return ForecastConfigurationFromJSONTyped(json, false);
}

export function ForecastConfigurationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ForecastConfiguration {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    conversionGroups: !exists(json, "conversionGroups")
      ? undefined
      : json["conversionGroups"],
    displayContactDistribution: !exists(json, "displayContactDistribution")
      ? undefined
      : json["displayContactDistribution"],
    displayAbsoluteNumbers: !exists(json, "displayAbsoluteNumbers")
      ? undefined
      : json["displayAbsoluteNumbers"],
    additionalCoverage: !exists(json, "additionalCoverage")
      ? undefined
      : ForecastCoverageEnumFromJSON(json["additionalCoverage"]),
  };
}

export function ForecastConfigurationToJSON(
  value?: ForecastConfiguration | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    conversionGroups: value.conversionGroups,
    displayContactDistribution: value.displayContactDistribution,
    displayAbsoluteNumbers: value.displayAbsoluteNumbers,
    additionalCoverage: ForecastCoverageEnumToJSON(value.additionalCoverage),
  };
}
