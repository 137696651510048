/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ForecastForInitialRequestsResultItem,
  ForecastForInitialRequestsResultItemFromJSON,
  ForecastForInitialRequestsResultItemFromJSONTyped,
  ForecastForInitialRequestsResultItemToJSON,
} from "./";

/**
 *
 * @export
 * @interface ForecastForInitialRequestsResult
 */
export interface ForecastForInitialRequestsResult {
  /**
   *
   * @type {{ [key: string]: ForecastForInitialRequestsResultItem; }}
   * @memberof ForecastForInitialRequestsResult
   */
  byId: { [key: string]: ForecastForInitialRequestsResultItem };
  /**
   *
   * @type {ForecastForInitialRequestsResultItem}
   * @memberof ForecastForInitialRequestsResult
   */
  combined?: ForecastForInitialRequestsResultItem;
}

export function ForecastForInitialRequestsResultFromJSON(
  json: any
): ForecastForInitialRequestsResult {
  return ForecastForInitialRequestsResultFromJSONTyped(json, false);
}

export function ForecastForInitialRequestsResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ForecastForInitialRequestsResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    byId: mapValues(json["byId"], ForecastForInitialRequestsResultItemFromJSON),
    combined: !exists(json, "combined")
      ? undefined
      : ForecastForInitialRequestsResultItemFromJSON(json["combined"]),
  };
}

export function ForecastForInitialRequestsResultToJSON(
  value?: ForecastForInitialRequestsResult | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    byId: mapValues(value.byId, ForecastForInitialRequestsResultItemToJSON),
    combined: ForecastForInitialRequestsResultItemToJSON(value.combined),
  };
}
