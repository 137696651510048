import "./forecast.less";

import { LoadingOutlined } from "@ant-design/icons";
import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  Divider,
  Form,
  Icons,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "@ster/ster-toolkit";
import { Form as AntForm } from "antd";
// import { AlignType } from 'rc-table/lib/interface';
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import {
  ForecastConfiguration,
  ForecastCoverageEnum,
  ForecastResult,
  ForecastTargetGroup,
  MediumEnum,
} from "../../../api";
import ForecastContactDistributionTable from "./ForecastContactDistributionTable";
import ForecastRow from "./ForecastRow";
import InlineEditableCoverageDropdown from "./InlineEditableCoverageDropdown";
import { forecastCoverageEnumTranslations } from "./shared";

type ForecastForm = {
  displayAbsoluteNumbers?: boolean;
  displayContactDistribution?: boolean;
  additionalCoverage: ForecastCoverageEnum;
};

export interface ForecastProps {
  medium?: MediumEnum;
  conversionGroups?: ForecastTargetGroup[];
  result?: ForecastResult;
  loadingForecast: boolean;
  configuration?: ForecastConfiguration;
  onConfigurationChange?: (config: Partial<ForecastConfiguration>) => void;
}

const defaultForecastCoverageEnum = ForecastCoverageEnum.Coverage3;

const Forecast = memo(
  ({
    medium,
    conversionGroups,
    configuration,
    result: forecast,
    loadingForecast: loading,
    onConfigurationChange: updateConfiguration,
  }: ForecastProps) => {
    const { AdultsIcon, CampaignIcon, EyeIcon, StatsIcon, GroupIcon } = Icons;
    const { Title } = Typography;
    const enableFormFields = !!updateConfiguration;

    // Form variables
    const [form] = AntForm.useForm<ForecastForm>();

    const {
      forecasts,
      conversionForecasts,
      unknownPackageCodes,
      unknownChannels,
      yourTimeRequirementsNotMet,
      top2000RequirementsNotMet,
      regioChannelForecastNotAvailable,
      regioPackageForecastNotAvailable,
    } = forecast || {};

    const [selectedConversionGroup, setSelectedConversionGroup] = useState("");

    const handleChangeConversionGroup = useCallback((option: string) => {
      setSelectedConversionGroup(option);
    }, []);

    const handleAddConversionGroup = useCallback(() => {
      const newConversionGroups = [
        ...(configuration?.conversionGroups ?? []),
        selectedConversionGroup,
      ];
      updateConfiguration?.({ conversionGroups: newConversionGroups });
    }, [
      updateConfiguration,
      configuration?.conversionGroups,
      selectedConversionGroup,
    ]);

    const handleDeleteConversionGroup = useCallback(
      (targetGroup: string) => {
        const newConversionGroups = (
          configuration?.conversionGroups ?? []
        ).filter((s) => s !== targetGroup);
        updateConfiguration?.({ conversionGroups: newConversionGroups });
      },
      [configuration?.conversionGroups, updateConfiguration]
    );

    // Update form to new configuration
    useEffect(() => {
      const formData = {
        additionalCoverage:
          configuration?.additionalCoverage ?? defaultForecastCoverageEnum,
        displayAbsoluteNumbers: configuration?.displayAbsoluteNumbers ?? false,
        displayContactDistribution:
          configuration?.displayContactDistribution ?? false,
      };

      form.setFieldsValue(formData);
    }, [
      form,
      configuration?.additionalCoverage,
      configuration?.displayAbsoluteNumbers,
      configuration?.displayContactDistribution,
    ]);

    const showNoForecastAvailableMessage = useMemo(
      () => !forecasts || forecasts.length === 0,
      [forecasts]
    );

    // Contact distribution (e.g. all form fields and the distribution table) are only available for radio.
    const enableContactDistribution = medium === MediumEnum.Radio;
    const displayContactDistribution =
      configuration?.displayContactDistribution === true;

    // Initial form values
    const initialFormValues: ForecastForm = {
      displayAbsoluteNumbers: false,
      displayContactDistribution: false,
      additionalCoverage: defaultForecastCoverageEnum,
    };

    // Form onValuesChange
    const handleValuesChange = useCallback(
      (_: Partial<ForecastForm>, values: ForecastForm) => {
        updateConfiguration?.({
          additionalCoverage: values.additionalCoverage,
          displayAbsoluteNumbers: values.displayAbsoluteNumbers,
          displayContactDistribution: values.displayContactDistribution,
        });
      },
      [updateConfiguration]
    );

    const displayedAdditionalCoverage = enableContactDistribution
      ? configuration?.additionalCoverage ?? defaultForecastCoverageEnum
      : undefined;

    return (
      <section className="forecast">
        <Form<ForecastForm>
          layout="horizontal"
          form={form}
          onValuesChange={handleValuesChange}
          initialValues={initialFormValues}
        >
          <div className="forecast-title-wrapper">
            <div className="center-part">
              <Title level={3}>
                <Trans>Prognose</Trans>
              </Title>
            </div>
            <div className="right-part">
              {enableContactDistribution && enableFormFields && (
                <Form.Item<ForecastForm>
                  name="displayAbsoluteNumbers"
                  label={i18n._(t`Toon absolute aantallen`)}
                >
                  <Switch
                    checkedChildren={i18n._(t`aan`)}
                    unCheckedChildren={i18n._(t`uit`)}
                  />
                </Form.Item>
              )}
            </div>
          </div>
          <div className="forecast-header">
            <div className="forecast-col">
              <AdultsIcon width={40} height={40} fill="#fff" />
            </div>
            <div className="forecast-col">
              <CampaignIcon width={40} height={40} fill="#fff" />
            </div>
            <div className="forecast-col">
              <GroupIcon width={65} height={40} fill="#fff" />
            </div>
            <div className="forecast-col">
              <EyeIcon width={40} height={40} fill="#fff" />
            </div>
            <div className="forecast-col">
              <StatsIcon width={40} height={40} fill="#fff" />
            </div>
          </div>
          <div className="forecast-header">
            <div className="forecast-col">
              <span className="forecast-col__stat">
                <Trans>Doelgroep</Trans>
              </span>
            </div>
            <div className="forecast-col">
              <span className="forecast-col__stat">
                1+ <Trans>bereik</Trans>
              </span>
            </div>
            {enableContactDistribution ? (
              <div className="forecast-col">
                <span className="forecast-col__stat">
                  {enableFormFields ? (
                    <Form.Item<ForecastForm> name="additionalCoverage">
                      <InlineEditableCoverageDropdown />
                    </Form.Item>
                  ) : (
                    <>
                      {displayedAdditionalCoverage &&
                        forecastCoverageEnumTranslations[
                          displayedAdditionalCoverage
                        ]}{" "}
                      <Trans>bereik</Trans>
                    </>
                  )}
                </span>
              </div>
            ) : (
              <div className="forecast-col">
                <span className="forecast-col__stat">
                  <Trans>Bereik in aantal</Trans>
                </span>
              </div>
            )}
            <div className="forecast-col">
              <span className="forecast-col__stat">
                <Trans>GCF</Trans>
                <Tooltip
                  title={i18n._(
                    medium === MediumEnum.Tv
                      ? t`Doorgaans adviseren wij een gemiddelde contact frequentie (GCF) tussen de 3 en 5 contacten per campagne.`
                      : t`Gemiddelde contact frequentie`
                  )}
                  placement="bottom"
                >
                  <span>
                    <Icons.QuestionIcon fill="white" width={16} height={16} />
                  </span>
                </Tooltip>
              </span>
            </div>
            <div className="forecast-col">
              <span className="forecast-col__stat">
                <Trans>GRP</Trans>
                <Tooltip
                  title={i18n._(t`Bruto bereik in %`)}
                  placement="bottom"
                >
                  <span>
                    <Icons.QuestionIcon fill="white" width={16} height={16} />
                  </span>
                </Tooltip>
              </span>
            </div>
          </div>
          <Divider className="forecast-divider" />
          {loading ? (
            <div className="forecast-row">
              <div className="forecast-col loading">
                <span className="forecast-col__value">
                  <LoadingOutlined />
                </span>
              </div>
            </div>
          ) : (
            <>
              {yourTimeRequirementsNotMet || top2000RequirementsNotMet ? (
                <>
                  {yourTimeRequirementsNotMet && medium === MediumEnum.Tv && (
                    <div className="forecast-row">
                      <Trans>
                        Voor een prognose op het Your Time TV-pakket of bij
                        specifieke inkoop dien je minimaal 25 GRP&apos;s te
                        selecteren
                      </Trans>
                    </div>
                  )}
                  {yourTimeRequirementsNotMet &&
                    medium === MediumEnum.Radio && (
                      <div className="forecast-row">
                        <Trans>
                          Voor een prognose op het Your Time radiopakket of bij
                          specifieke inkoop, dien je de aanvraag te combineren
                          met een GRP-inkoopoptie.
                        </Trans>
                      </div>
                    )}
                  {top2000RequirementsNotMet && medium === MediumEnum.Radio && (
                    <div className="forecast-row">
                      <Trans>
                        Op dit moment is het niet mogelijk een prognose te maken
                        waarin Top 2000 wordt gecombineerd met andere
                        inkoopopties.
                      </Trans>
                    </div>
                  )}
                </>
              ) : (
                showNoForecastAvailableMessage && (
                  <div className="forecast-row">
                    <Trans>Nog geen prognose beschikbaar</Trans>
                  </div>
                )
              )}
              {forecasts?.map((forecastTarget) => (
                <ForecastRow
                  key={JSON.stringify(forecastTarget)}
                  forecastTarget={forecastTarget}
                  displayAbsoluteNumbers={
                    configuration?.displayAbsoluteNumbers === true
                  }
                  additionalCoverage={displayedAdditionalCoverage}
                />
              ))}
              {conversionForecasts?.map((forecastTarget) => (
                <ForecastRow
                  forecastTarget={forecastTarget}
                  deleteConversionGroup={
                    enableFormFields ? handleDeleteConversionGroup : undefined
                  }
                  displayAbsoluteNumbers={
                    configuration?.displayAbsoluteNumbers === true
                  }
                  additionalCoverage={displayedAdditionalCoverage}
                  key={JSON.stringify(forecastTarget)}
                />
              ))}
              {unknownPackageCodes &&
                (unknownPackageCodes?.length ?? 0) > 0 && (
                  <div className="forecast-row">
                    <Trans>
                      Voor de volgende pakket(ten) is geen prognose beschikbaar:{" "}
                      {unknownPackageCodes.join(", ")}
                    </Trans>
                  </div>
                )}
              {unknownChannels &&
                (unknownChannels?.filter((s) =>
                  regioChannelForecastNotAvailable ? s !== "TV Regio" : true
                ).length ?? 0) > 0 && (
                  <div className="forecast-row">
                    <Trans>
                      Voor de volgende zender(s) is geen prognose beschikbaar:{" "}
                      {unknownChannels.join(", ")}
                    </Trans>
                  </div>
                )}
              {regioChannelForecastNotAvailable && (
                <div className="forecast-row">
                  <Trans>
                    Deze prognose gaat uit van het bereik exclusief het bereik
                    op de regionale omroepen. Zodra er voldoende data
                    beschikbaar is zal het bereik van de regionale omroepen
                    toegevoegd worden.
                  </Trans>
                </div>
              )}
              {regioPackageForecastNotAvailable && (
                <div className="forecast-row">
                  <Trans>
                    Op dit moment is het nog niet mogelijk om een prognose te
                    tonen voor het Regio pakket. Zodra er voldoende data
                    beschikbaar is zal de prognose worden toegevoegd.
                  </Trans>
                </div>
              )}

              {(displayContactDistribution || enableFormFields) && (
                <Divider className="forecast-divider" />
              )}

              {((enableContactDistribution && enableFormFields) ||
                displayContactDistribution) &&
                forecasts && (
                  <div className="forecast-contactdistribution-container">
                    {enableFormFields && (
                      <Form.Item<ForecastForm>
                        name="displayContactDistribution"
                        label={i18n._(t`Contactdistributie`)}
                      >
                        <Switch
                          checkedChildren={i18n._(t`aan`)}
                          unCheckedChildren={i18n._(t`uit`)}
                        />
                      </Form.Item>
                    )}
                    {displayContactDistribution && (
                      <ForecastContactDistributionTable
                        forecastTargets={[
                          ...forecasts,
                          ...(conversionForecasts ?? []),
                        ]}
                      />
                    )}
                  </div>
                )}
            </>
          )}
        </Form>
        {enableFormFields && conversionGroups && (
          <div className="add-conversion-group">
            <Select.Search<string, ForecastTargetGroup>
              placeholder={i18n._(t`Kies een secundaire doelgroep`)}
              optionFilterProp="children"
              options={conversionGroups}
              fieldNames={{ label: "displayName", value: "code" }}
              onSelect={handleChangeConversionGroup}
            />
            <Button
              mode="tertiary"
              disabled={!selectedConversionGroup}
              onClick={handleAddConversionGroup}
            >
              <Icons.PlusIcon fill="#008ccc" />
              <Trans>Voeg doelgroep toe</Trans>
            </Button>
          </div>
        )}
      </section>
    );
  }
);

export default Forecast;
