import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Input } from "@ster/ster-toolkit";
import { App as AntApp } from "antd";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { ReduxStoreState } from "../../../../store/base";
import { receiveCalculationAction } from "../../../../store/campaignCreate/actions";
import { StoreModel } from "../../../../store/models";
import { hasKey } from "../../../../utils";
import { usePrevious } from "../../../../utils/hooks";
import SimpleSpinner from "../../../partials/SimpleSpinner";
import { calculationSelector } from "../selectors";
import { CalculateProps } from "./models";

const CalculateGrpBudget = memo(
  forwardRef(
    (
      {
        value,
        onCalculated,
        onChange,
        subOrder,
        productId,
        medium,
        onClick,
        onBlur,
        onLoading,
        name,
        isActive = true,
        disabled,
        calculateTimeStamp,
        className,
        id,
        requestDate,
      }: CalculateProps,
      _
    ) => {
      const elementId = id ?? name;
      const { i18n } = useLingui();
      const { message } = AntApp.useApp();
      const dispatch = useDispatch();

      // Gebruik een ref zodat we altijd de huidige value terug kunnen geven in de debounced functie
      const stateRef = useRef<number | undefined>();
      stateRef.current = value;

      const { calculation } = useSelector((state: StoreModel) =>
        calculationSelector(state, subOrder.id ?? 0)
      );

      const prevCalculationStoreState = usePrevious(calculation.state);

      const isLoading = useMemo(
        () =>
          calculation.loading &&
          calculation.calculation?.initiator === elementId,
        [calculation.calculation?.initiator, calculation.loading, elementId]
      );

      const handleCalculationUpdate = useCallback(() => {
        if (calculation.state === ReduxStoreState.Success) {
          // Geef het resultaat van de berekening terug, voor het veld wat de berekening geinitieerd heeft nemen we de huidige waarde
          // voor als de gebruiker nog aan het typen was
          onCalculated({
            ...calculation.calculation,
            [name]: stateRef.current,
          });
        } else if (calculation.state === ReduxStoreState.Failure) {
          message.error(i18n._(t`Er ging iets mis bij de berekening`));
          onCalculated({});
        }
      }, [
        calculation.calculation,
        calculation.state,
        i18n,
        message,
        name,
        onCalculated,
      ]);

      useEffect(() => {
        if (
          prevCalculationStoreState === ReduxStoreState.Loading &&
          calculation.state !== prevCalculationStoreState &&
          calculation.calculation &&
          calculation.calculation?.initiator === elementId &&
          hasKey(calculation.calculation, name) &&
          calculation.calculation[name] === stateRef.current
        ) {
          // Als er een calculatie is voltooid handelen we deze af als deze door de huidige initiator is aangevraagd
          // en de huidige ingevulde waarde gelijk is aan die in de berekening
          handleCalculationUpdate();
        }
      }, [
        calculation.calculation,
        calculation.state,
        handleCalculationUpdate,
        elementId,
        prevCalculationStoreState,
        name,
      ]);

      const calculate = useCallback(
        (propName: string, propValue: number) => {
          if (onLoading) {
            onLoading(true);
          }

          dispatch(
            receiveCalculationAction.request({
              calculationRequest: {
                channels: subOrder.channels?.filter((c) => !!c),
                dateFrom: subOrder.period?.from,
                dateTo: subOrder.period?.to,
                excludedDays: subOrder.excluded,
                packageCode: subOrder._package?.code,
                productId,
                spotLength: subOrder.spotLength?.reduce(
                  (a, b) => a + (b ?? 0),
                  0
                ),
                targetGroupId: subOrder.targetGroup?.targetGroupId,
                [propName]: Number(propValue),
                startTime: subOrder.timeSelection && subOrder.timeSelection[0],
                endTime:
                  subOrder.timeSelection &&
                  subOrder.timeSelection[subOrder.timeSelection.length - 1],
                format: subOrder.format,
                discountProperties: subOrder.discountProperties,
                requestDate,
              },
              medium,
              subOrderId: subOrder.id ?? 0,
              initiator: elementId,
            })
          );
        },
        [
          onLoading,
          dispatch,
          subOrder.channels,
          subOrder.period?.from,
          subOrder.period?.to,
          subOrder.excluded,
          subOrder._package?.code,
          subOrder.spotLength,
          subOrder.targetGroup?.targetGroupId,
          subOrder.timeSelection,
          subOrder.format,
          subOrder.discountProperties,
          subOrder.id,
          productId,
          requestDate,
          medium,
          elementId,
        ]
      );

      const debouncedCalculate = useDebouncedCallback(calculate, 500);

      const handleChange = useCallback(
        (changedValue: string | number | undefined) => {
          if (
            isActive &&
            changedValue &&
            changedValue.toString() !== value?.toString()
          ) {
            if (onLoading) {
              // Trigger de onloading zodat andere velden niet meer gewijzigd kunnen worden tijdens de invoer
              onLoading(true);
            }
            debouncedCalculate.cancel();
            debouncedCalculate(name, changedValue as number);
            if (onChange) {
              onChange(Number(changedValue));
            }
          }
        },
        [debouncedCalculate, isActive, name, onChange, onLoading, value]
      );

      const [prevCalculateTimeStamp, setPrevCalculateTimeStamp] = useState<
        number | undefined
      >();
      useEffect(() => {
        // Als het veld calculateTimeStamp wijzigt voeren we een herberekening uit
        if (
          calculateTimeStamp &&
          (!prevCalculateTimeStamp ||
            calculateTimeStamp !== prevCalculateTimeStamp) &&
          value
        ) {
          debouncedCalculate.cancel();
          debouncedCalculate(name, value);
          if (prevCalculateTimeStamp === undefined) {
            // When there is no timestamp, can trigger imediately
            debouncedCalculate.flush();
          }
          setPrevCalculateTimeStamp(calculateTimeStamp);
        }
      }, [
        debouncedCalculate,
        name,
        calculateTimeStamp,
        value,
        isActive,
        prevCalculateTimeStamp,
      ]);

      switch (name) {
        case "grp":
          return (
            <>
              <Input.Decimal
                name={name}
                onClick={onClick}
                onFocus={onClick}
                onBlur={onBlur}
                onChange={handleChange}
                value={value}
                decimalScale={2}
                fixedDecimalScale
                disabled={disabled}
                className={className}
              />
              {isLoading && <SimpleSpinner />}
            </>
          );
        case "budget":
          return (
            <>
              <Input.Currency
                name={name}
                onClick={onClick}
                onFocus={onClick}
                onBlur={onBlur}
                onChange={handleChange}
                value={value}
                disabled={disabled}
                className={className}
                decimalScale={0}
              />
              {isLoading && <SimpleSpinner />}
            </>
          );
        case "spotsPerDay":
        case "spotsPerDayChannel":
          return (
            <>
              <Input.Decimal
                name={name}
                onClick={onClick}
                onFocus={onClick}
                onBlur={onBlur}
                onChange={handleChange}
                value={value}
                disabled={disabled}
                decimalScale={1}
                className={className}
              />
              {isLoading && <SimpleSpinner />}
            </>
          );
        case "impressions":
          return (
            <>
              <Input.Decimal
                name={name}
                onClick={onClick}
                onFocus={onClick}
                onBlur={onBlur}
                onChange={handleChange}
                value={value}
                disabled={disabled}
                decimalScale={0}
                className={className}
              />
              {isLoading && <SimpleSpinner />}
            </>
          );
        default:
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
      }
    }
  )
);

export default CalculateGrpBudget;
