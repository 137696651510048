import { memo } from "react";

import { ForecastForInitialRequestsResultItem, OrderListItem } from "../../api";
import CampaignCard from "../partials/Cards/Campaign/CampaignCard";
import Forecast from "../partials/Forecast/Forecast";

const ProposalCampaign = memo(
  ({
    campaign,
    forecast,
  }: {
    campaign: OrderListItem;
    forecast?: ForecastForInitialRequestsResultItem;
  }) => (
    <>
      <CampaignCard cardData={campaign} openDetailsInNewTab />
      {forecast && (
        <Forecast
          medium={campaign.medium}
          loadingForecast={false}
          configuration={forecast._configuration}
          result={forecast.result}
        />
      )}
    </>
  )
);

export default ProposalCampaign;
