import { ReactNode, createContext, useContext, useMemo } from "react";
import { useSelector } from "react-redux";

import { OrderRequest } from "../../api";
import { StoreModel } from "../../store/models";
import { calculationLoadingStateSelector } from "../campaignCreate/subOrder/selectors";

interface CampaignContextType {
  orderRequest: OrderRequest | undefined;
  updateOrderRequest: (
    updater: (
      prevOrderRequest: OrderRequest | undefined
    ) => Partial<OrderRequest>
  ) => void;
  isLoadingCalculation: boolean;
}

const CampaignContext = createContext<CampaignContextType | undefined>(
  undefined
);

export const useCampaign = () => {
  const context = useContext(CampaignContext);
  if (!context) {
    throw new Error("useCampaign must be used within a CampaignProvider");
  }
  return context;
};

interface CampaignProviderProps {
  children: ReactNode;
  orderRequest?: OrderRequest;
  updateOrderRequest: (
    updater: (
      prevOrderRequest: OrderRequest | undefined
    ) => Partial<OrderRequest>
  ) => void;
}

export const CampaignProvider = ({
  children,
  orderRequest,
  updateOrderRequest,
}: CampaignProviderProps) => {
  const subOrderIds = useMemo(
    () => orderRequest?.subOrders?.map((subOrder) => Number(subOrder.id)) ?? [],
    [orderRequest?.subOrders]
  );
  const isLoadingCalculation = useSelector((state: StoreModel) =>
    calculationLoadingStateSelector(state, subOrderIds)
  );

  // value provided by this provider
  const value = useMemo<CampaignContextType>(
    () => ({
      orderRequest,
      updateOrderRequest,
      isLoadingCalculation,
    }),
    [orderRequest, updateOrderRequest, isLoadingCalculation]
  );

  return (
    <CampaignContext.Provider value={value}>
      {children}
    </CampaignContext.Provider>
  );
};
