import "./forecast.less";

import { Trans } from "@lingui/macro";
import { Select } from "@ster/ster-toolkit";
import { SelectProps } from "antd";
import { PropsWithChildren } from "react";

import { ForecastCoverageEnum } from "../../../api";
import { forecastCoverageEnumTranslations } from "./shared";

type SelectForecastCoverageEnumOptionType = {
  label: string;
  value: ForecastCoverageEnum;
};

interface InlineEditableCoverageDropdownProps {
  value?: ForecastCoverageEnum;
  onChange?: SelectProps<
    ForecastCoverageEnum,
    SelectForecastCoverageEnumOptionType
  >["onChange"];
}

const InlineEditableCoverageDropdown = ({
  value,
  onChange,
}: PropsWithChildren<InlineEditableCoverageDropdownProps>) => {
  const options = Object.keys(ForecastCoverageEnum)
    .map((key) => key as ForecastCoverageEnum)
    .filter((f) => f !== ForecastCoverageEnum.Coverage1)
    .map(
      (key) =>
        ({
          label: forecastCoverageEnumTranslations[key],
          value: key,
        }) as SelectForecastCoverageEnumOptionType
    );

  return (
    <div className="inline-editable-dropdown">
      <Select<ForecastCoverageEnum, SelectForecastCoverageEnumOptionType>
        value={value}
        onChange={onChange}
        className="inline-select"
        popupClassName="inline-select-dropdown"
        options={options}
      />
      <span>
        {" "}
        <Trans>bereik</Trans>
      </span>
    </div>
  );
};

export default InlineEditableCoverageDropdown;
