import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useDebouncedCallback } from "use-debounce";

import { ForecastConfiguration, PackageChoiceEnum } from "../../../api";
import { ReduxStoreState } from "../../../store/base";
import {
  receiveForecastAction,
  receiveForecastConversionGroupsAction,
} from "../../../store/campaignCreate/actions";
import { subOrderGenericFieldsFilled } from "../../../utils";
import {
  forecastConversionGroupsSelector,
  forecastConversionGroupsStateSelector,
  forecastSelector,
  forecastStateSelector,
  loadingForecastSelector,
} from "../../campaigns/selectors";
import { useCampaign } from "../../providers/CampaignProvider";
import Forecast from "./Forecast";

const campaignForecastSelector = createStructuredSelector({
  forecastState: forecastStateSelector,
  forecast: forecastSelector,
  loadingForecast: loadingForecastSelector,
  forecastConversionGroups: forecastConversionGroupsSelector,
  forecastConversionGroupsState: forecastConversionGroupsStateSelector,
});

const CampaignForecast = () => {
  const dispatch = useDispatch();
  const { orderRequest, updateOrderRequest, isLoadingCalculation } =
    useCampaign();

  const {
    forecast,
    loadingForecast,
    forecastConversionGroups,
    forecastConversionGroupsState,
  } = useSelector(campaignForecastSelector);

  // Setup
  useEffect(() => {
    if (forecastConversionGroupsState === ReduxStoreState.Initial) {
      dispatch(receiveForecastConversionGroupsAction.request());
    }
  }, [dispatch, forecastConversionGroupsState]);

  // Determine suborders that are valid to dispatch.
  const forecastCalculationSubOrders = useMemo(
    () =>
      orderRequest?.subOrders
        ?.filter(
          (s) =>
            (subOrderGenericFieldsFilled(s) && s.grp) ||
            (s.packageChoice &&
              [
                PackageChoiceEnum.FixedCosts,
                PackageChoiceEnum.FixedCostsCult,
              ].includes(s.packageChoice) &&
              (s.breakSelection?.length ?? 0) > 0)
        )
        // Filter de lege spotlengtes
        .map((s) => ({
          ...s,
          spotLength: (s.spotLength ?? []).filter((sl) => sl),
        })),
    [orderRequest?.subOrders]
  );

  // Generate unique key based on orderRequest and conversionGroups
  // This key is used as dependency to determine if the forecast should be recalculated
  const forecastCalculationKey = useMemo(
    () =>
      [
        forecastCalculationSubOrders
          ?.map((s) =>
            [
              s.id ?? "0",
              s.breakSelection
                ?.map((bs) => `${bs.key ?? ""} ${bs.preferredPosition ?? ""}`)
                .join("/") ?? "",
            ].join("-")
          )
          .join("|"),
        ...(orderRequest?.forecastConfiguration?.conversionGroups ?? []),
        isLoadingCalculation, // Forces a forecast recalculation when the loading calculation state changes
      ].join("~"),
    [
      forecastCalculationSubOrders,
      orderRequest?.forecastConfiguration?.conversionGroups,
      isLoadingCalculation,
    ]
  );

  // Ophalen van prognose
  const handleForecastCalculation = useCallback(() => {
    if (
      !orderRequest?.medium ||
      !forecastCalculationSubOrders ||
      forecastCalculationSubOrders.length === 0 ||
      isLoadingCalculation
    ) {
      return;
    }

    dispatch(
      receiveForecastAction.request({
        input: {
          subOrders: forecastCalculationSubOrders,
          conversionGroups:
            orderRequest?.forecastConfiguration?.conversionGroups,
          productId: orderRequest.productId,
        },
        initialRequestId: orderRequest.id ?? undefined,
        medium: orderRequest.medium,
      })
    );
  }, [
    dispatch,
    isLoadingCalculation,
    orderRequest?.id,
    orderRequest?.medium,
    orderRequest?.productId,
    forecastCalculationSubOrders,
    orderRequest?.forecastConfiguration?.conversionGroups,
  ]);

  // Delayed ophalen van prognose
  const debouncedForecast = useDebouncedCallback(
    handleForecastCalculation,
    500
  );

  // Request forecast data on effect - yes, initial load also goes through debounce - nobody will notice, probably.
  useEffect(() => {
    if (forecastCalculationKey.length > 0) {
      debouncedForecast.cancel();
      debouncedForecast();
    }
  }, [forecastCalculationKey, debouncedForecast]);

  const handleConfigurationChange = useCallback(
    (partialConfiguration: Partial<ForecastConfiguration>) => {
      updateOrderRequest((prevOrderRequest) => ({
        forecastConfiguration: {
          ...(prevOrderRequest?.forecastConfiguration ?? {
            displayAbsoluteNumbers: false,
            displayContactDistribution: false,
          }),
          ...partialConfiguration,
        },
      }));
    },
    [updateOrderRequest]
  );

  return (
    <Forecast
      loadingForecast={loadingForecast}
      onConfigurationChange={handleConfigurationChange}
      configuration={orderRequest?.forecastConfiguration}
      result={forecast}
      medium={orderRequest?.medium}
      conversionGroups={
        (orderRequest?.medium &&
          forecastConversionGroups[orderRequest?.medium]) ??
        []
      }
    />
  );
};

export default CampaignForecast;
