/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ForecastCoverageEnum {
  Coverage1 = "Coverage1",
  Coverage2 = "Coverage2",
  Coverage3 = "Coverage3",
  Coverage4 = "Coverage4",
  Coverage5 = "Coverage5",
  Coverage6 = "Coverage6",
  Coverage7 = "Coverage7",
  Coverage8 = "Coverage8",
  Coverage9 = "Coverage9",
  Coverage10 = "Coverage10",
}

export function ForecastCoverageEnumFromJSON(json: any): ForecastCoverageEnum {
  return ForecastCoverageEnumFromJSONTyped(json, false);
}

export function ForecastCoverageEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ForecastCoverageEnum {
  return json as ForecastCoverageEnum;
}

export function ForecastCoverageEnumToJSON(
  value?: ForecastCoverageEnum | null
): any {
  return value as any;
}
