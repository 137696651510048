/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ForecastResultTargetGroupForecasts,
  ForecastResultTargetGroupForecastsFromJSON,
  ForecastResultTargetGroupForecastsFromJSONTyped,
  ForecastResultTargetGroupForecastsToJSON,
} from "./";

/**
 *
 * @export
 * @interface ForecastResultTargetGroup
 */
export interface ForecastResultTargetGroup {
  /**
   *
   * @type {string}
   * @memberof ForecastResultTargetGroup
   */
  targetGroup: string;
  /**
   *
   * @type {string}
   * @memberof ForecastResultTargetGroup
   */
  targetGroupDisplayName: string;
  /**
   *
   * @type {ForecastResultTargetGroupForecasts}
   * @memberof ForecastResultTargetGroup
   */
  forecasts: ForecastResultTargetGroupForecasts;
}

export function ForecastResultTargetGroupFromJSON(
  json: any
): ForecastResultTargetGroup {
  return ForecastResultTargetGroupFromJSONTyped(json, false);
}

export function ForecastResultTargetGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ForecastResultTargetGroup {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    targetGroup: json["targetGroup"],
    targetGroupDisplayName: json["targetGroupDisplayName"],
    forecasts: ForecastResultTargetGroupForecastsFromJSON(json["forecasts"]),
  };
}

export function ForecastResultTargetGroupToJSON(
  value?: ForecastResultTargetGroup | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    targetGroup: value.targetGroup,
    targetGroupDisplayName: value.targetGroupDisplayName,
    forecasts: ForecastResultTargetGroupForecastsToJSON(value.forecasts),
  };
}
