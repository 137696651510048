import "./forecast.less";

import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { Icons, formatNumber } from "@ster/ster-toolkit";
// import { AlignType } from 'rc-table/lib/interface';
import { PropsWithChildren, memo, useCallback } from "react";

import { ForecastCoverageEnum, ForecastResultTargetGroup } from "../../../api";

const ForecastColumn = ({ children }: PropsWithChildren<unknown>) => (
  <div className="forecast-col">
    <span className="forecast-col__value">{children}</span>
  </div>
);

const ForecastRow = memo(
  ({
    forecastTarget,
    deleteConversionGroup,
    additionalCoverage,
    displayAbsoluteNumbers,
  }: {
    forecastTarget: ForecastResultTargetGroup;
    deleteConversionGroup?: (targetGroup: string) => void;
    additionalCoverage?: ForecastCoverageEnum;
    displayAbsoluteNumbers: boolean;
  }) => {
    const canDelete = deleteConversionGroup !== undefined;
    const forecast = forecastTarget.forecasts.Coverage1;
    const otherForecast = additionalCoverage
      ? forecastTarget.forecasts[additionalCoverage]
      : undefined;

    const handleDeleteConversionGroup = useCallback(() => {
      deleteConversionGroup?.(forecastTarget.targetGroup);
    }, [deleteConversionGroup, forecastTarget.targetGroup]);

    return (
      <div className="forecast-row">
        <ForecastColumn>
          <span className="singleValue">
            {forecastTarget.targetGroupDisplayName}
          </span>
        </ForecastColumn>
        <ForecastColumn>
          <span className="multiValue">
            <span className="percentage">
              {forecast && formatNumber(forecast.bereikPercentage ?? 0, 1)}%
            </span>
            {displayAbsoluteNumbers ? (
              <span className="absolute">
                ({forecast && formatNumber(forecast.bereikNumber ?? 0)})
              </span>
            ) : null}
          </span>
        </ForecastColumn>
        {otherForecast ? (
          <ForecastColumn>
            <span className="multiValue">
              <span className="percentage">
                {otherForecast &&
                  formatNumber(otherForecast.bereikPercentage ?? 0, 1)}
                %
              </span>
              {displayAbsoluteNumbers ? (
                <span className="absolute">
                  (
                  {otherForecast &&
                    formatNumber(otherForecast.bereikNumber ?? 0)}
                  )
                </span>
              ) : null}
            </span>
          </ForecastColumn>
        ) : (
          <ForecastColumn>
            <span className="singleValue">
              {forecast && formatNumber(forecast.bereikNumber ?? 0)}
            </span>
          </ForecastColumn>
        )}
        <ForecastColumn>
          <span className="singleValue">
            {forecast && formatNumber(forecast.gcf, 1)}X
          </span>
        </ForecastColumn>
        <ForecastColumn>
          <span className="singleValue">
            {forecast && formatNumber(forecast.grp, 1)}
          </span>
        </ForecastColumn>
        {canDelete && (
          <div className="forecast-col delete">
            <span className="forecast-col__value">
              <span
                role="button"
                onClick={handleDeleteConversionGroup}
                tabIndex={0}
                aria-label={i18n._(t`Verwijder doelgroep`)}
                title={i18n._(t`Verwijder doelgroep`)}
              >
                <Icons.DeleteIcon fill="#fff" />
              </span>
            </span>
          </div>
        )}
      </div>
    );
  }
);

export default ForecastRow;
