/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CampaignCalculatorForecastResult,
  CampaignCalculatorForecastResultFromJSON,
  CampaignCalculatorForecastResultToJSON,
  ForecastConversionGroups,
  ForecastConversionGroupsFromJSON,
  ForecastConversionGroupsToJSON,
  ForecastInput,
  ForecastInputFromJSON,
  ForecastInputToJSON,
  ForecastResult,
  ForecastResultFromJSON,
  ForecastResultToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1ForecastRadioPostRequest {
  forecastInput?: ForecastInput;
  initialRequestId?: number;
}

export interface ApiV1ForecastRadioV2PostRequest {
  forecastInput?: ForecastInput;
  initialRequestId?: number;
}

export interface ApiV1ForecastTvPostRequest {
  forecastInput?: ForecastInput;
  initialRequestId?: number;
}

export interface ApiV1ForecastTvV2PostRequest {
  forecastInput?: ForecastInput;
  initialRequestId?: number;
}

/**
 *
 */
export class ForecastApi extends runtime.BaseAPI {
  /**
   */
  async apiV1ForecastConversiongroupsGetRaw(): Promise<
    runtime.ApiResponse<ForecastConversionGroups>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Forecast/conversiongroups`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ForecastConversionGroupsFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ForecastConversiongroupsGet(): Promise<ForecastConversionGroups> {
    const response = await this.apiV1ForecastConversiongroupsGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1ForecastRadioPostRaw(
    requestParameters: ApiV1ForecastRadioPostRequest
  ): Promise<runtime.ApiResponse<CampaignCalculatorForecastResult>> {
    const queryParameters: any = {};

    if (requestParameters.initialRequestId !== undefined) {
      queryParameters["initialRequestId"] = requestParameters.initialRequestId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Forecast/radio`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ForecastInputToJSON(requestParameters.forecastInput),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CampaignCalculatorForecastResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ForecastRadioPost(
    requestParameters: ApiV1ForecastRadioPostRequest
  ): Promise<CampaignCalculatorForecastResult> {
    const response = await this.apiV1ForecastRadioPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1ForecastRadioV2PostRaw(
    requestParameters: ApiV1ForecastRadioV2PostRequest
  ): Promise<runtime.ApiResponse<ForecastResult>> {
    const queryParameters: any = {};

    if (requestParameters.initialRequestId !== undefined) {
      queryParameters["initialRequestId"] = requestParameters.initialRequestId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Forecast/radio/v2`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ForecastInputToJSON(requestParameters.forecastInput),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ForecastResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ForecastRadioV2Post(
    requestParameters: ApiV1ForecastRadioV2PostRequest
  ): Promise<ForecastResult> {
    const response = await this.apiV1ForecastRadioV2PostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1ForecastTvPostRaw(
    requestParameters: ApiV1ForecastTvPostRequest
  ): Promise<runtime.ApiResponse<CampaignCalculatorForecastResult>> {
    const queryParameters: any = {};

    if (requestParameters.initialRequestId !== undefined) {
      queryParameters["initialRequestId"] = requestParameters.initialRequestId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Forecast/tv`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ForecastInputToJSON(requestParameters.forecastInput),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CampaignCalculatorForecastResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ForecastTvPost(
    requestParameters: ApiV1ForecastTvPostRequest
  ): Promise<CampaignCalculatorForecastResult> {
    const response = await this.apiV1ForecastTvPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1ForecastTvV2PostRaw(
    requestParameters: ApiV1ForecastTvV2PostRequest
  ): Promise<runtime.ApiResponse<ForecastResult>> {
    const queryParameters: any = {};

    if (requestParameters.initialRequestId !== undefined) {
      queryParameters["initialRequestId"] = requestParameters.initialRequestId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Forecast/tv/v2`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ForecastInputToJSON(requestParameters.forecastInput),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ForecastResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ForecastTvV2Post(
    requestParameters: ApiV1ForecastTvV2PostRequest
  ): Promise<ForecastResult> {
    const response = await this.apiV1ForecastTvV2PostRaw(requestParameters);
    return await response.value();
  }
}
