/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Forecast
 */
export interface Forecast {
  /**
   *
   * @type {number}
   * @memberof Forecast
   */
  bereikPercentage: number;
  /**
   *
   * @type {number}
   * @memberof Forecast
   */
  gcf: number;
  /**
   *
   * @type {number}
   * @memberof Forecast
   */
  bereikNumber?: number;
  /**
   *
   * @type {number}
   * @memberof Forecast
   */
  grp: number;
}

export function ForecastFromJSON(json: any): Forecast {
  return ForecastFromJSONTyped(json, false);
}

export function ForecastFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Forecast {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bereikPercentage: json["bereikPercentage"],
    gcf: json["gcf"],
    bereikNumber: !exists(json, "bereikNumber")
      ? undefined
      : json["bereikNumber"],
    grp: json["grp"],
  };
}

export function ForecastToJSON(value?: Forecast | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bereikPercentage: value.bereikPercentage,
    gcf: value.gcf,
    bereikNumber: value.bereikNumber,
    grp: value.grp,
  };
}
