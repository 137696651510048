import { Spinner } from "@ster/ster-toolkit";
import { Store } from "antd/es/form/interface";
import { isDate } from "date-fns";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormFinishInfo } from "rc-field-form/lib/FormContext";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormInstance } from "rc-field-form/lib/interface";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { MediumEnum, OrderRequest, SubOrderRequest } from "../../api";
import { ReduxStoreState } from "../../store/base";
import {
  clearForecastAction,
  clearGroupedPackagesRadioAction,
  clearGroupedPackagesTvAction,
  clearPackagesAction,
  clearProductsAction,
  receiveCustomerContactClearAction,
  receiveCustomerOpportunitiesClearAction,
  receiveInitialRequestAction,
  receiveSalesPeriodsAction,
} from "../../store/campaignCreate/actions";
import { clearCampaignInstructionsAction } from "../../store/campaignDetail/actions";
import { receiveEditableCampaignAction } from "../../store/campaignEdit/actions";
import { StoreModel } from "../../store/models";
import CampaignCreate from "../campaignCreate/CampaignCreate";
import CampaignEdit from "../campaignEdit/CampaignEdit";
import { CampaignProvider } from "../providers/CampaignProvider";
import { ValidationResults } from "./models";
import { campaignCreateEditSelector } from "./selectors";

interface CampaignCreateEditProps {
  isEdit?: boolean;
}

const CampaignCreateEdit = memo(({ isEdit }: CampaignCreateEditProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    orderId: orderIdFromParams,
    initialRequestId: initialRequestIdFromParams,
    medium: mediumFromParams,
    from: fromFromParams,
    to: toFromParams,
  } = useParams<{
    medium?: string;
    orderId?: string;
    initialRequestId?: string;
    from?: string;
    to?: string;
  }>();

  const medium = mediumFromParams as MediumEnum;
  const orderId = Number(orderIdFromParams ?? 0);
  const initialRequestId = Number(initialRequestIdFromParams ?? 0);
  const from = useMemo(
    () => (fromFromParams ? new Date(fromFromParams) : undefined),
    [fromFromParams]
  );
  const to = useMemo(
    () => (toFromParams ? new Date(toFromParams) : undefined),
    [toFromParams]
  );
  const [orderRequest, setOrderRequest] = useState<OrderRequest>();

  const { initialRequest, campaign, campaignState } = useSelector(
    (state: StoreModel) => {
      const date =
        from && isDate(from) ? from : orderRequest?.period?.from ?? new Date();
      return campaignCreateEditSelector(
        state,
        date,
        initialRequestId !== 0 ? initialRequestId : orderId
      );
    }
  );

  useEffect(() => {
    setOrderRequest(initialRequest || campaign);
  }, [campaign, initialRequest]);

  useEffect(() => {
    // Ophalen van een concept
    if (!isEdit && !initialRequest && initialRequestId && from && to) {
      // Haal de bestaande aanvraag op
      dispatch(
        receiveInitialRequestAction.request({
          medium,
          initialRequestId,
          from: new Date(from),
          to: new Date(to),
        })
      );
    }
  }, [dispatch, from, initialRequest, initialRequestId, isEdit, medium, to]);

  useEffect((): void => {
    // Ophalen van een campagne
    if (!isEdit || campaignState !== ReduxStoreState.Initial) {
      return;
    }

    if (!campaign) {
      dispatch(
        receiveEditableCampaignAction.request({
          medium,
          orderId,
        })
      );
    }
  }, [campaign, campaignState, dispatch, isEdit, medium, orderId]);

  const reset = useCallback(() => {
    dispatch(receiveSalesPeriodsAction.request());
    dispatch(clearCampaignInstructionsAction());
    dispatch(clearForecastAction());
    dispatch(clearProductsAction());
    dispatch(receiveCustomerOpportunitiesClearAction());
    dispatch(receiveCustomerContactClearAction());
    dispatch(clearPackagesAction());
    dispatch(clearGroupedPackagesRadioAction());
    dispatch(clearGroupedPackagesTvAction());
  }, [dispatch]);

  useEffect(() => {
    reset();
  }, [reset]);

  const handleCancel = useCallback(() => {
    // reset
    setOrderRequest(undefined);
    reset();

    if (location.key === "default") {
      // geen vorige pagina's
      navigate("/campaigns");
    } else {
      navigate(-1);
    }
  }, [location.key, navigate, reset]);

  const handleUpdateOrderRequest = useCallback(
    (
      fn: (prevOrderRequest: OrderRequest | undefined) => Partial<OrderRequest>
    ) => {
      setOrderRequest((prevOrderRequest) => {
        const partial = fn(prevOrderRequest);
        return { ...(prevOrderRequest as OrderRequest), ...partial };
      });
    },
    []
  );

  // Toevoegen van een nieuwe deelorder
  const handleAddNewSubOrder = useCallback(
    (subOrder: Partial<SubOrderRequest>) => {
      handleUpdateOrderRequest((prevOrderRequest) => {
        const newSubOrders = [
          ...(prevOrderRequest?.subOrders ?? []),
          subOrder as SubOrderRequest,
        ];
        return { subOrders: newSubOrders };
      });
    },
    [handleUpdateOrderRequest]
  );

  // Verwijderen van een deelorder
  const handleDeleteSubOrder = useCallback(
    (id: number) => {
      handleUpdateOrderRequest((prevOrderRequest) => {
        const newSubOrders =
          prevOrderRequest?.subOrders?.filter((s) => s.id !== id) ?? [];
        return {
          subOrders: [...newSubOrders],
        };
      });
    },
    [handleUpdateOrderRequest]
  );

  // Wijzigen van een bestaande deelorder
  const handleSubOrderChange = useCallback(
    (subOrder: Partial<SubOrderRequest>) => {
      handleUpdateOrderRequest((prevOrderRequest) => {
        const currentOrders = prevOrderRequest?.subOrders ?? [];
        const index = currentOrders.map(({ id }) => id).indexOf(subOrder.id);
        const newSubOrders = [
          ...currentOrders.slice(0, index),
          { ...currentOrders[index], ...subOrder },
          ...currentOrders.slice(index + 1),
        ];

        return {
          subOrders: newSubOrders,
        };
      });
    },
    [handleUpdateOrderRequest]
  );

  const validateForm = useCallback(
    (form: FormInstance): Promise<boolean> =>
      form
        .validateFields()
        .then(() => true)
        .catch(() => false),
    []
  );

  const handleFormFinish = useCallback(
    (
      name: string,
      { forms, values }: FormFinishInfo,
      handleFormValid: (
        formName: string,
        validationResults: ValidationResults[],
        formValues: Store
      ) => void
    ) => {
      // Alleen afhandeling als er op een submit knop in een save-formulier gedrukt wordt
      const formsToHandle = [
        "save",
        "finalize",
        "saveSubOrdersNext",
        "saveSubOrdersPrevious",
        "proposal",
        "forward",
      ];
      if (!formsToHandle.includes(name)) {
        return;
      }

      // Controleer of er nog formulieren zijn met errors
      const validations = Object.entries<FormInstance>(forms).map(
        (
          form: [string, FormInstance]
        ): Promise<{ name: string; valid: boolean }> => {
          // Het details formulier niet saven als de suborders opgeslagen worden
          if (name.startsWith("saveSubOrders") && form[0] === "details") {
            return Promise.resolve({ name: form[0], valid: true });
          }

          // De formulier die alleen een knop bevatten dienen niet gesubmit te worden
          if (!formsToHandle.includes(form[0])) {
            // Als de submit failed zal deze de `onFinishFailed` binnen het formulier triggeren
            return Promise.resolve(validateForm(form[1])).then((result) => {
              form[1].submit();
              return { name: form[0], valid: result };
            });
          }

          return Promise.resolve({ name: form[0], valid: true });
        }
      );

      Promise.all(validations).then(
        (validationResults: ValidationResults[]) => {
          handleFormValid(name, validationResults, values);
        }
      );
    },
    [validateForm]
  );

  return (
    <CampaignProvider
      orderRequest={orderRequest}
      updateOrderRequest={handleUpdateOrderRequest}
    >
      {isEdit ? (
        <Spinner spinning={campaignState === ReduxStoreState.Loading}>
          {orderRequest && (
            <CampaignEdit
              onDeleteSubOrder={handleDeleteSubOrder}
              onAddSubOrder={handleAddNewSubOrder}
              onChangeSubOrder={handleSubOrderChange}
              onFormFinish={handleFormFinish}
              orderRequest={orderRequest}
              updateOrderRequest={setOrderRequest}
              onCancel={handleCancel}
            />
          )}
        </Spinner>
      ) : (
        <CampaignCreate
          onDeleteSubOrder={handleDeleteSubOrder}
          onAddSubOrder={handleAddNewSubOrder}
          onChangeSubOrder={handleSubOrderChange}
          onFormFinish={handleFormFinish}
          orderRequest={orderRequest}
          updateOrderRequest={setOrderRequest}
          onCancel={handleCancel}
        />
      )}
    </CampaignProvider>
  );
});

export default CampaignCreateEdit;
