/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Forecast,
  ForecastFromJSON,
  ForecastFromJSONTyped,
  ForecastToJSON,
} from "./";

/**
 *
 * @export
 * @interface ForecastResultTargetGroupForecasts
 */
export interface ForecastResultTargetGroupForecasts {
  /**
   *
   * @type {Forecast}
   * @memberof ForecastResultTargetGroupForecasts
   */
  Coverage1?: Forecast;
  /**
   *
   * @type {Forecast}
   * @memberof ForecastResultTargetGroupForecasts
   */
  Coverage2?: Forecast;
  /**
   *
   * @type {Forecast}
   * @memberof ForecastResultTargetGroupForecasts
   */
  Coverage3?: Forecast;
  /**
   *
   * @type {Forecast}
   * @memberof ForecastResultTargetGroupForecasts
   */
  Coverage4?: Forecast;
  /**
   *
   * @type {Forecast}
   * @memberof ForecastResultTargetGroupForecasts
   */
  Coverage5?: Forecast;
  /**
   *
   * @type {Forecast}
   * @memberof ForecastResultTargetGroupForecasts
   */
  Coverage6?: Forecast;
  /**
   *
   * @type {Forecast}
   * @memberof ForecastResultTargetGroupForecasts
   */
  Coverage7?: Forecast;
  /**
   *
   * @type {Forecast}
   * @memberof ForecastResultTargetGroupForecasts
   */
  Coverage8?: Forecast;
  /**
   *
   * @type {Forecast}
   * @memberof ForecastResultTargetGroupForecasts
   */
  Coverage9?: Forecast;
  /**
   *
   * @type {Forecast}
   * @memberof ForecastResultTargetGroupForecasts
   */
  Coverage10?: Forecast;
}

export function ForecastResultTargetGroupForecastsFromJSON(
  json: any
): ForecastResultTargetGroupForecasts {
  return ForecastResultTargetGroupForecastsFromJSONTyped(json, false);
}

export function ForecastResultTargetGroupForecastsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ForecastResultTargetGroupForecasts {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Coverage1: !exists(json, "Coverage1")
      ? undefined
      : ForecastFromJSON(json["Coverage1"]),
    Coverage2: !exists(json, "Coverage2")
      ? undefined
      : ForecastFromJSON(json["Coverage2"]),
    Coverage3: !exists(json, "Coverage3")
      ? undefined
      : ForecastFromJSON(json["Coverage3"]),
    Coverage4: !exists(json, "Coverage4")
      ? undefined
      : ForecastFromJSON(json["Coverage4"]),
    Coverage5: !exists(json, "Coverage5")
      ? undefined
      : ForecastFromJSON(json["Coverage5"]),
    Coverage6: !exists(json, "Coverage6")
      ? undefined
      : ForecastFromJSON(json["Coverage6"]),
    Coverage7: !exists(json, "Coverage7")
      ? undefined
      : ForecastFromJSON(json["Coverage7"]),
    Coverage8: !exists(json, "Coverage8")
      ? undefined
      : ForecastFromJSON(json["Coverage8"]),
    Coverage9: !exists(json, "Coverage9")
      ? undefined
      : ForecastFromJSON(json["Coverage9"]),
    Coverage10: !exists(json, "Coverage10")
      ? undefined
      : ForecastFromJSON(json["Coverage10"]),
  };
}

export function ForecastResultTargetGroupForecastsToJSON(
  value?: ForecastResultTargetGroupForecasts | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Coverage1: ForecastToJSON(value.Coverage1),
    Coverage2: ForecastToJSON(value.Coverage2),
    Coverage3: ForecastToJSON(value.Coverage3),
    Coverage4: ForecastToJSON(value.Coverage4),
    Coverage5: ForecastToJSON(value.Coverage5),
    Coverage6: ForecastToJSON(value.Coverage6),
    Coverage7: ForecastToJSON(value.Coverage7),
    Coverage8: ForecastToJSON(value.Coverage8),
    Coverage9: ForecastToJSON(value.Coverage9),
    Coverage10: ForecastToJSON(value.Coverage10),
  };
}
